
/* Global*/
*, *::before, *::after {
  box-sizing: border-box;
  /* cursor: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' ...") 16 16, pointer; */
  /* cursor: url('./images/ic_menu_undo_a&d.png'), auto; */
}
html{
    scroll-behavior: smooth;    
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body{
    position: fixed;
    width: 100%;
}

:root{
    --toolbar-width: 39.8px;
    --toolbar-icon-height: 31.99px;
    --toolbar-icon-padding: 0.25rem;
    --toolbar-wrapper-width: 52px;
    --thumbnail-slider-width: 220px;
    --image-box-width: 145px;

    --page-and-video-tool-bar-height: 50px;
    --gray-gradient-color: linear-gradient(rgba(0,0,0,.06),rgba(0,0,0,0));
    --videos-width: 240px;
    --insert-page-height: 22px;
    --banner-height: 50px;
    --textboxFontSize: 2rem;
    /* --toastify-toast-background: red !important; */
    /* --toastify-color-info: red !important; */
}  
body.disallowed-mobile{
    font-size: 1.4rem;
    background: #53877ae8;
    color: white;
    overflow: hidden;
    touch-action: none;
    pointer-events: none;
    position: fixed;
}
body.disallowed-mobile #root{
    overflow: hidden;
    pointer-events: none;
}
body.disallowed-mobile #root .disallowed-mobile-text{
    line-height: 3rem;
    text-align: left;
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pointer-none{
    pointer-events: none;
}
input:disabled{
    user-select: none;
}
[draggable="true"] {
    /*
     To prevent user selecting inside the drag source
    */
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}


.App{
    font-size: 1.1rem;
    display: grid;
    font-family:Microsoft JhengHei;
    /* grid-template-rows: minmax(10vh, auto) ; */
}
.App a{
    text-decoration: none;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
.text-center{
    text-align: center;
}
button{
    cursor: pointer;
    font-family: inherit;
}
.customized-btn{
    border-radius: 4px;
    width: 310px;
    height: 46px;
}
.btn-dark{
    background: #45635D;
    color: #FFFFFF;
}
.btn-dark:hover{
    background:#578B7F;
}
.btn-dark:active{
    background:#185345;
}
.btn-light{
    background: #DCEFEB;
    border: 1px solid #B2D2CB;
    color: #000000;
    font-weight: bold;
}
.btn-light:hover{
    background: #F1FCFA;   
}
.btn-light:active{
    background: #CEE2DE;
}
.warning{
    color: #FF5454;

}
.displayNone{
    display: none;
}


/* nav style*/
nav{
    min-height: 10vh;
    border: 1px solid black;
    /* background-color: lightblue; */
    background-color: #cfe7ee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    flex-direction: column;
    /* position: fixed; */
    width: 100%;
    border: 0;
}
.nav-links{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0.3rem;
}

.nav-links > li{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 30.99px;
    min-height: 30.99px;
}
.nav-links > li >span{
    padding: 2%;
    border-radius: 1rem;
}





.btn.btn-secondary:disabled{
    background-color: #c1c1c1;
}

/* .nav-links li, .nav-links .toolbar button{
    font-size: .85rem;
} */
.nav-links li{
    font-size: .85rem;
}

.room-nav{
    padding-top: 2rem;
    padding-bottom: .8rem;
    flex-direction: column-reverse;
}
.room-nav .title {
    /* padding: .3rem */
    display: none
}


/*banner*/
.banner{
    background-color: #E3F5F1;
    height: var(--banner-height);
    /* border-bottom: 2px solid black; */
    display: flex;
    align-items: center;
    user-select: none;
    padding: 0 1em 0 3em;
    justify-content: space-between;
    
}
.banner span{
    font: normal normal normal 22px/14px Roboto;
    color: #49C9AB;
}
.banner button{
    background: #F56B4E;
    color: #FFFFFF;
    min-width: 90px;
    width:max-content;
    height: 30px;
    border-radius: 20px;
    font: normal normal normal 15px/14px Microsoft JhengHei;
    border: 0;
    padding: 0 1em;
    
}
.banner button:hover{
    background:#F8856C;
}
.banner button:active{
    background:#E55334;
}



/* home style */
.home{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    grid-auto-rows: minmax(8rem, 8rem);
    padding: 1rem;
    overflow: scroll;
}
.room-icon{
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    border: 1px solid #c5c5c5;
    text-align: center;
}
.room-icon > span{
    min-height: 7vh;
    margin: 2%;
}
.room-icon > button{
    margin: 2%;
    border-radius: 1rem;
}

/* room style */
.room{
    /* display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column; */
    /* margin-left: 1em; */
    touch-action: none;
    user-select: none;
    overflow-x: hidden;
    /* min-height: 88vh;
    min-height: -webkit-fill-available; */
    /* min-height: -webkit-fill-available; */
    /* overflow: hidden; */
    display: flex;
    /* margin-top: var(--page-and-video-tool-bar-height); */
    /* height: calc(100vh - var(--page-and-video-tool-bar-height) - 100px); */
    /* margin-top: 1vh;
    margin-bottom: 1vh; */
    /* height: calc(100vh);   */
    /* height: calc(var(--vh, 5vh) * 95); */
    /* height: 100vh; */
    position: relative;
    /* display: grid; */
    /* grid-template-rows: 50px; */
    /* grid-template-columns: 1fr;  */

    height: calc(var(--div-height, 100vh));

}
.room.noHardware{
    height: calc(var(--div-height, 100vh) - var(--banner-height));
}
.room.noHardware.ios{
    /* height: calc(100vh - 125px); */
}
.room-login-box span{
    font-size: .9em;
}


.main-center{
    display: flex;
    flex-direction: column;
    max-width: calc(100vw - var(--toolbar-wrapper-width) - var(--videos-width) );
    width: 100%;
    touch-action: none;
    /* position: relative; */
    /* transition: transform linear 400ms; */
}

.whiteboard-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    touch-action: none;
    background:#808A88;
    z-index: 0;

    /* transition: width linear 400ms; */
}

.main-center.thumbnail-open{
    transform: translate(var(--thumbnail-slider-width));
    /* transition: transform linear 400ms; */
}
.whiteboard-container.thumbnail-open{
    width: calc(100% - var(--thumbnail-slider-width));
    /* transition: width linear 400ms; */
}
.whiteboard-options-control.thumbnail-open{
    transform: translate(calc(var(--thumbnail-slider-width) * -1));
    /* transition: transform ease-in-out  400ms; */
}

.hasHardware .whiteboard-container{
    border-top: 1px solid black;
}


.whiteboard-container::-webkit-scrollbar,
.thumbnail::-webkit-scrollbar{
    width: 11px;
    height: 11px;
    background-color: #fff;
}
.whiteboard-container::-webkit-scrollbar-thumb,
.thumbnail::-webkit-scrollbar-thumb {
    background-color: #B2D2CB;
    background-clip: padding-box;
    border-radius: 6px;
}
.whiteboard-container::-webkit-scrollbar-track,
.thumbnail::-webkit-scrollbar-track {
    background-clip: padding-box;
    border-width: 1px;
}
.whiteboard-container::-webkit-scrollbar-corner,
.thumbnail::-webkit-scrollbar-corner {
    transform: matrix(-1, 0, 0, -1, 0, 0);
    background: #B2D2CB 0% 0% no-repeat padding-box;
}
.whiteboard-container::-webkit-scrollbar-button:single-button:vertical:decrement,
.thumbnail::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-radius: 5px 5px 0 0;
    height: 16px;
    width: 16px;
    background-position: center 4px;
  }

.minimap-wrapper{
    position: absolute;
    box-shadow: 0 0 0px 3px #45635D;
    border-radius: 5px;
    background: #45635D;
    z-index: 100;
    padding: 2.6rem .6rem .6rem .6rem;
}

.minimap-wrapper .title{
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    color: white;
    font-size: 1.1rem;
    cursor: move;
    height: 2.6rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.minimap{
    background: white;
}
.minimap img{
    opacity: 1;
    z-index: 1;
    transition-delay: 0ms;
    background: white;
    position: absolute;
    width: inherit;
    height: inherit;
}
.minimap.loading img{
    opacity: 0;
    transition: 300ms opacity ease-in-out;
    z-index: 1;
}

.minimap .map{
    width: 100%;
    height: 100%;
    position: relative;
    cursor: default;
    z-index: 2;
}
.minimap .map .range{
    position: absolute;
    left: 0;
    top: 0;
    transition: ease 100ms;
    background: transparent;
    border-radius: 2px;
    box-shadow: 0px 0px 1px 1px white;
}
.minimap .map .range.isdragging{
    transition: none;
}
.range.isdragging:active{
    cursor: move;
}

.minimap .map .opponent-minimap{
    border: 2px solid #E25A95;
}
.minimap .map .my-minimap{
    border: 2px solid #6DD2BA;
    cursor: move;
}
.minimap{
    transition: background-image ease-in 80ms;
    /* opacity: 1; */
    /* transition: opacity ease-in 50ms; */
    background-repeat: no-repeat;
}
.minimap.loading{
    /* opacity: 0.9; */
    /* display: none; */
}
/* .whiteboard-container svg .shapes,
.whiteboard-container svg .marks{
    opacity: 1;
    transition: opacity 5000ms ease-in-out;
} */

.whiteboard-container svg .shapes,
.whiteboard-container svg .marks,
.whiteboard-container svg .image-inks{
    animation: opacityToNormal 250ms;

}
.whiteboard-container.loading svg .shapes,
.whiteboard-container.loading svg .marks,
.whiteboard-container.loading svg .image-inks{
    opacity: 0;
}

.whiteboard-container.content-hidden svg .shapes,
.whiteboard-container.content-hidden svg .marks,
.whiteboard-container.content-hidden svg .image-inks{
    opacity: 0;
}


  

.whiteboard-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-self: center;
    padding: 14px;
    background: #808A88;
    flex: 1;
    width: 100%;
    height: 100%;
}
.whiteboard-drag{
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 14px;
    right: 14px;
    background-repeat: no-repeat;
    background-image: url('./images/ic_drag-board@2x.png');
    background-size: 30px 30px;
    cursor: se-resize;
    display: none;
    pointer-events: none;

}

.whiteboard{
    width:500px;
    height: 500px;
    border: 1px solid black;
    background-color: #FFFFFF;
    border-radius: 5px;
}
.whiteboard-options-control{
    display: flex;
    align-items: center;
    flex: 0 0 60px;
    border-top: 1px solid #808A88;
    border-left: 1px solid #808A88;
    border-right: 1px solid #808A88;
    border-bottom: 1px solid #808A88;
    background: #E3F5F1;
    position: relative;
    /* transition: transform ease-in-out 400ms; */
}
.whiteboard-options-control span{
    margin: 0 .4em 0 .7em;
    font: normal normal normal 16px/14px Roboto;
    height: 24px;
    display: flex !important;
    align-items: center;
    /* scale: 0; */
}
.whiteboard-options-control #whiteboard-size{
    display: none !important;
    pointer-events: none;
}
.whiteboard-options-control span input{
    max-width: 4em;
    max-height: 1.7em;
    font-size: .9em;
}
.page-control-container{
    display: flex;
    align-items: center;
}
.whiteboard-options-control .pageControl.disable{
    color: darkgray;
}

.actualSize{
    background-image: url('./images/ic_board-viewer_actual-size_n@2x.png');
}
.actualSize:hover{
    background-image: url('./images/ic_board-viewer_actual-size_h@2x.png');
}
.actualSize:active{
    background-image: url('./images/ic_board-viewer_actual-size_d@2x.png');
}
.zoomIn{
    background-image: url('./images/ic_board-viewer_zoom-in_n@2x.png');
}
.zoomIn:hover{
    background-image: url('./images/ic_board-viewer_zoom-in_h@2x.png');
}
.zoomIn:active{
    background-image: url('./images/ic_board-viewer_zoom-in_d@2x.png');
}
.zoomOut{
    background-image: url('./images/ic_board-viewer_zoom-out_n@2x.png');
}
.zoomOut:hover{
    background-image: url('./images/ic_board-viewer_zoom-out_h@2x.png');
}
.zoomOut:active{
    background-image: url('./images/ic_board-viewer_zoom-out_d@2x.png');
}
.fillInWindow{
    background-image: url('./images/ic_board-viewer_fit-screen_n@2x.png');
}
.fillInWindow:hover{
    background-image: url('./images/ic_board-viewer_fit-screen_h@2x.png');
}
.fillInWindow:active{
    background-image: url('./images/ic_board-viewer_fit-screen_d@2x.png');
}
.syncViewport{
    background-image: url('./images/ic_board-viewer_sync-view_n@2x.png');
}
.syncViewport:hover{
    background-image: url('./images/ic_board-viewer_sync-view_h@2x.png');
}
.syncViewport:active{
    background-image: url('./images/ic_board-viewer_sync-view_d@2x.png');
}

.firstPage{
    background-image: url('./images/ic_page-top_n@2x.png');
}
.firstPage:hover{
    background-image: url('./images/ic_page-top_h@2x.png');
}
.firstPage:active{
    background-image: url('./images/ic_page-top_d@2x.png');
}
.previousPage{
    background-image: url('./images/ic_previous-page_n@2x.png');
}
.previousPage:hover{
    background-image: url('./images/ic_previous-page_h@2x.png');
}
.previousPage:active{
    background-image: url('./images/ic_previous-page_d@2x.png');
}
.nextPage{
    background-image: url('./images/ic_next-page_n@2x.png');
}
.nextPage:hover{
    background-image: url('./images/ic_next-page_h@2x.png');
}
.nextPage:active{
    background-image: url('./images/ic_next-page_d@2x.png');
}
.lastPage{
    background-image: url('./images/ic_page-end_n@2x.png');
}
.lastPage:hover{
    background-image: url('./images/ic_page-end_h@2x.png');
}
.lastPage:active{
    background-image: url('./images/ic_page-end_d@2x.png');
}
.previousPage, .nextPage{
    width: 13.3px;
    height: 20px !important;
    cursor: pointer;
    background-size: cover;
}
.firstPage, .lastPage{
    width: 24.16px;
    height: 20px !important;
    cursor: pointer;
    background-size: cover;
}

.page-control-container.readonly .firstPage, 
.pageControl.disable .firstPage{
    pointer-events: none;
    background-image: url('./images/ic_page-top_g@2x.png') !important;
}
.page-control-container.readonly .previousPage, 
.pageControl.disable .previousPage{
    pointer-events: none;
    background-image: url('./images/ic_previous-page_g@2x.png') !important;
}

.page-control-container.readonly .nextPage, 
.pageControl.disable .nextPage{
    pointer-events: none;
    background-image: url('./images/ic_next-page_g@2x.png') !important;
}

.page-control-container.readonly .lastPage, 
.pageControl.disable .lastPage{
    pointer-events: none;
    background-image: url('./images/ic_page-end_g@2x.png') !important;
}
.page-control-container.readonly .currentPageIdx,
.currentPageIdx-container.disable .currentPageIdx{
    pointer-events: none;
    color: #a4bcb7;
    border: 1px solid #a4bcb780;
}
.page-control-container.readonly .currentPageIdx-container,
.currentPageIdx-container.disable{
    color:#a4bcb7;
}



.actualSize, .zoomIn, .zoomOut, .fillInWindow, .syncViewport{
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: cover;
}

.whiteboard-options-control .page-control-changer{
    position: absolute;
    display: flex;
    align-items: center;
    right: 1.5em;
    top: 50%;
    transform: translate(0, -50%);
}
.page-control-changer>select{
    font-size: 16px;
    padding: 0.2em;
}


.thumbnail{
    width: var(--thumbnail-slider-width);
    height: calc(100% - 60px);
    left: var(--toolbar-wrapper-width);
    padding: 20px 0px;
    position: absolute;
    border-left: 1px solid #808A88;
    border-top: 1px solid #808A88;
    border-bottom: 1px solid #808A88; 
    background: #E3F5F1;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    /* transition: width padding linear 200ms; */
    /* transition: width linear 200ms; */
}



.thumbnail .image-container{
    display: flex;
    justify-content: center;
    padding: .8rem 0;
    position: relative;
    margin-left: 1.1rem;
}
.thumbnail .image-container .serial{
    font-size: 1.1rem;
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translate(0, -50%);
}
.thumbnail .image-container .image-box{
    width: var(--image-box-width);
    height: 80px;
    cursor: pointer;
    background: white;
    position: relative;
}
.image-container::before{
    content: attr(data-pagenum);
    position: absolute;
    font-size: .9rem;
    z-index: 1;
    width: max-content;
    right: 180px;
    text-align: right;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: bold;
}
.thumbnail .image-container .image-box span{
    display: none;
}
.thumbnail .image-container .image-box img{
    width: 100%;
    height: 100%;
    /* -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none; */
}
.thumbnail .image-container .image-box img.loading{
    display: none;
    /* -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none; */
}

.thumbnail .image-container .image-box span.loading{
    /* position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    display: block;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('./images/ic_loading@2x.gif')
}
.thumbnail .image-container .image-box:hover{
    box-shadow: 0px 0px 1px 2px lightgray;
    /* border: 2px solid lightgray; */
}
.thumbnail .image-container .image-box.selected{
    box-shadow: 0px 0px 1px 2px black;
    /* border: 2px solid black; */
}
.thumbnail .image-container .more-action.unselected{
    display: none;
}
.thumbnail .image-container .more-action.selected{
    display: block;
}
.thumbnail .image-container .image-box .without-box-shadow:hover,
.thumbnail .image-container .image-box .without-box-shadow{
    box-shadow: none;
}
.thumbnail .image-container .image-box.selected.dragging{
    opacity: 0.33;
    
    box-shadow: none;
    border: 2px solid rgb(0 0 0 / 0.5);
}
.thumbnail .image-container .more-action{
    width: 22px;
    height: 22px;
    background-image: url('./images/ic_video-control_more_n@2x.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 2px;
    top: 2px;
    cursor: pointer;
    z-index: 3;
}
.thumbnail .image-container .more-action:hover{
    background-image: url('./images/ic_video-control_more_h@2x.png');
}
.thumbnail .image-container .more-action:active{
    background-image: url('./images/ic_video-control_more_d@2x.png');
}
.more-action-menu{
    position: absolute;
    background: #b2d2cb;
    color: #000;
    padding: 6px;
    border-radius: 5px;
    width: 75px;
    top: 31px;
    right: -20px;
    z-index: 3;
    font-size: 14px;
    cursor:pointer;
}
.more-action-menu:hover{
    background-color:#adc7c1;
}

.thumbnail.hide{
    width: 0px;
    border-left: 0px;
    transition:  linear 180ms;
    overflow-y: hidden;
}
.thumbnail.invitee{
    display: none;
    pointer-events: none;
}

.thumbnail .thumbnail-expand{
    background: url('./images/ic_video-bar-expand_n@2x.png');
     width: 16px;
     height: 32px;
     background-size: cover;
     cursor: pointer;
     position: fixed;
     top: 50%;
     transform: translateY(-50%);
     left: calc(var(--thumbnail-slider-width) + var(--toolbar-wrapper-width) - 17px);
     z-index: 101;
     transition:  linear 50ms;
 }
.thumbnail.hide .thumbnail-expand{
    background: url('./images/ic_video-bar-collapse_n@2x.png');
    width: 15px;
    height: 30px;
    background-size: cover;
    cursor: pointer;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: var(--toolbar-wrapper-width);
    z-index: 101;
    transition: linear 200ms;
}
.thumbnail .insert-page{
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--insert-page-height);
    margin-left: 1.1rem;
}
.thumbnail .insert-page .insert-page-icon{
    background-image: url('./images/ic_add-new-board_n@2x.png');
    display: block;
    height: var(--insert-page-height);
    width: var(--insert-page-height);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}
.thumbnail .insert-page .insert-page-icon:hover{
    background-image: url('./images/ic_add-new-board_h@2x.png');
}
.thumbnail .insert-page .insert-page-icon:active{
    background-image: url('./images/ic_add-new-board_d@2x.png');
}


.thumbnail.dragging .insert-page:not(.dragover) .insert-page-icon{
    visibility: hidden;
    pointer-events: none;
}
.thumbnail.dragging .insert-page.dragover .insert-page-icon{
    background-image: none;
    width: var(--image-box-width);
    height: 4px;
    margin-left: 1.1rem;
    background-color: rgb(108 151 141);
}
.thumbnail.dragging .image-container .more-action, .thumbnail.dragging .more-action-menu{
    visibility: hidden;
    pointer-events: none;
}
.page-detail{
    animation: opacityToNormal 250ms;
}

/*login*/
.login-form{
    position: relative;
    height: 100%;
    margin: 1em;
}
.login-form .login-form-content>input{
    /* margin: .5em 0; */
    width: 100%;
    font-size: .8em;
    height: 44px;
    border: 1px solid #B2D2CB;
    padding: 0.5em;
}

.login-form .login-form-footer{
    position: absolute;
    bottom: 1em;
    right: 0;
}
.login-form .select-login-way{
    position: absolute;
    bottom: 1em;
    left: 1em;
    right: 1em;
}
.login-form .select-login-way>button{
    width: 100%;
    padding: 0.3em 0;
    font-size: .9em;
}

.login-form .login-form-footer>button{
    margin: 0 .2em;
    font-size: .8em;
    padding: .5em 1.4em;
    text-decoration: none;
    width: 110px;
}
.login-form .warning{
    margin-bottom: .5em;
    font-size: .7em;
}
.login-form .device-setting-switch{
    margin-top: 24px;
    display: flex;
}
.login-form .device-setting-switch span{
    margin-right: .5em;
}
.switch{
    color:#494949;
    margin-right: 1em;
    width: 53px;
    height: 24px;
    cursor: pointer;
}
.switch-on{
    background-image: url('./images/ic_switch_on_n@2x.png');
    background-size: cover;
}
.switch-on:hover{
    background-image: url('./images/ic_switch_on_h@2x.png');
    background-size: cover;
}
.switch-on:active{
    background-image: url('./images/ic_switch_on_d@2x.png');
    background-size: cover;
}
.switch-off{
    background-image: url('./images/ic_switch_off_n@2x.png');
    background-size: cover;
}
.switch-off:hover{
    background-image: url('./images/ic_switch_off_h@2x.png');
    background-size: cover;
}
.switch-off:active{
    background-image: url('./images/ic_switch_off_d@2x.png');
    background-size: cover;
}


.btn-darkblue{
    background-color: #1c447e;
    color: #fff
}





/*shape drag and resize element style*/
.shapes .inactive.draggable{
    cursor: default;
}
.control-element.inactive{
    opacity: 0;
}
.shapes .active.draggable .shape, .shapes .active.draggable .dragrect,.shapes .active.draggable .textdragrect,
.image-inks .active.draggable .shape, .image-inks .active.draggable .dragrect,.image-inks .active.draggable .textdragrect{
    cursor: move;
}
.shapes .active.draggable ellipse, .shapes .active.draggable .textdragrect, 
.image-inks .active.draggable image, .shapes .active.draggable image{
    outline: 1.1px dashed gray;
    /* outline: 1px solid #e6e0e0; */
}
.shapes .active .control-element{
    visibility: visible;
}
.control-element.active .nw{
    cursor:crosshair;
}
.control-element.active .nw[data-isimageink='true']{
    cursor:nw-resize;
}
.control-element.active .rotate{
    cursor:crosshair;
    /* transform: scale(2) translate(-50%, -50%); */
    transform-box: fill-box;
}
/* .control-element.active .more{
    cursor: pointer;
    transform-box: fill-box;
}  */
.control-element.active .ne{
    cursor:ne-resize;
}
.control-element.active .sw{
    cursor:sw-resize;
}
.control-element.active .se{
    cursor:se-resize;
}
.control-element.active .n{
    cursor:n-resize;
}
.control-element.active .e{
    cursor:e-resize;
}
.control-element.active .s{
    cursor:s-resize;
}
.control-element.active .w{
    cursor:w-resize;
}
.control-element.active .lineControlBegin{
    cursor:ew-resize;
}
.control-element.active .lineControlEnd{
    cursor:ew-resize;
}
.control-element .rotate{
    width: 60px;
    height: 60px;
}
/* .control-element .imageinkcolor{
    transform: translate(8px, 8px);
} */

.imageinkcolorbg, .imageinkwidthbg, .imageinktoinkbg, .morebg{
    cursor: pointer;
    fill: white;
}
.imageinkcolor, .imageinkwidth, .imageinktoink, .more{
    pointer-events: none;
}
.imageinkcolorbg:hover, .imageinkwidthbg:hover, .imageinktoinkbg:hover, .morebg:hover{
    fill:#e3f5f1;
}
.shape .editable{
    pointer-events: all;
}
.shape .uneditable{
    pointer-events: none;
}


.shapes .active.draggable .shape[data-current='drag'], 
.shapes .active.draggable .dragrect[data-current='drag'],
.control-element.active *[data-current='drag'],
.whiteboard[data-current='drag']{
    cursor: move !important;
}
.shapes .active.draggable .shape[data-current='rotate'],
.shapes .active.draggable .dragrect[data-current='rotate'],
.control-element.active *[data-current='rotate'],
.whiteboard[data-current='rotate']{
    cursor: crosshair !important;
}
.shapes .active.draggable .shape[data-current='ne'],
.shapes .active.draggable .dragrect[data-current='ne'],
.image-inks .active.draggable .shape[data-current='ne'],
.image-inks .active.draggable .dragrect[data-current='ne'],
.control-element.active *[data-current='ne'],
.whiteboard[data-current='ne']{
    cursor: ne-resize !important;
}
.shapes .active.draggable .shape[data-current='sw'],
.shapes .active.draggable .dragrect[data-current='sw'],
.image-inks .active.draggable .shape[data-current='sw'],
.image-inks .active.draggable .dragrect[data-current='sw'],
.control-element.active *[data-current='sw'],
.whiteboard[data-current='sw']{
    cursor: sw-resize !important;
}
.shapes .active.draggable .shape[data-current='se'],
.shapes .active.draggable .dragrect[data-current='se'],
.image-inks .active.draggable .shape[data-current='se'],
.image-inks .active.draggable .dragrect[data-current='se'],
.control-element.active *[data-current='se'],
.whiteboard[data-current='se']{
    cursor: se-resize !important;
}
.shapes .active.draggable .shape[data-current='n'],
.shapes .active.draggable .dragrect[data-current='n'],
.control-element.active *[data-current='n'],
.whiteboard[data-current='n']{
    cursor: n-resize !important;
}
.shapes .active.draggable .shape[data-current='e'],
.shapes .active.draggable .dragrect[data-current='e'],
.control-element.active *[data-current='e'],
.whiteboard[data-current='e']{
    cursor: e-resize !important;
}
.shapes .active.draggable .shape[data-current='s'],
.shapes .active.draggable .dragrect[data-current='s'],
.control-element.active *[data-current='s'],
.whiteboard[data-current='s']{
    cursor: s-resize !important;
}
.shapes .active.draggable .shape[data-current='w'],
.shapes .active.draggable .dragrect[data-current='w'],
.control-element.active *[data-current='w'],
.whiteboard[data-current='w']{
    cursor: w-resize !important;
}
.shapes .active.draggable .shape[data-current='nw'],
.shapes .active.draggable .dragrect[data-current='nw'],
.image-inks .active.draggable .shape[data-current='nw'],
.image-inks .active.draggable .dragrect[data-current='nw'],
.control-element.active *[data-current='nw'],
.whiteboard[data-current='nw']{
    cursor: nw-resize !important;
}
.control-element[data-ismine='false'], .shape[data-ismine='false']{
    pointer-events: none !important;
}

.whiteboard.erasermode, .whiteboard.erasermode .shapes .draggable{
    cursor: url('./images/ic_cusor_eraser_n.png'), auto !important
}
.whiteboard.penmode, .whiteboard.penmode .shapes .draggable{
    cursor: url('./images/ic_cursor_pen.png'), auto !important
}
.whiteboard .loading-circle-group .loading-circle{
    opacity: 0;
    pointer-events: none;
}
.whiteboard-container.loading{
    pointer-events: none;
}
.whiteboard-container.loading .whiteboard .loading-circle-group .loading-circle{
    opacity: 1;
    width: 70px;
    height: 70px;
    transition: 50ms opacity ease-in-out;
    /* transform: translate(50%, 50%); */
}

.loading-circle-group{
    transform: translate(var(--translateX, 0), var(--translateY, 0));
}
.whiteboard .loading-circle-group .loading-circle.loadimg{
    opacity: 1;
    width: 2.2em;
    height: 2.2em;
}
.whiteboard.pointer-none{
    pointer-events: none !important;
}

/* shape-more-option */
.shape-more-option g .no-submenu{
    fill: #94ccec;
    fill-opacity: 0;
}
.shape-more-option g .has-submenu{
    fill-opacity: 0;
}
.shape-more-option g .no-submenu.hover{
    fill-opacity: 0.3;
    stroke: #00aed1;
}
.shape-more-option g .has-submenu.hover{
    stroke: #00aed1;
}
.shape-more-option-text{
    font-size: 13px;
}
.shape-more-option g.disabled .no-submenu.hover{
    fill-opacity: 0;
    stroke-opacity: 0;
}
.shape-more-option g.disabled .shape-more-option-text{
    fill: #a1a1a1;
}


/* .control-element.inactive{
    visibility: hidden;
    
}
.control-element.active .dragrect{
    visibility: visible;
    stroke: #a7a7a7;
    fill: transparent;
    stroke-dasharray: 0.1;
    cursor: move;
} */


.device-setting-modal{
    width: 470px;
    height: 600px;
    border-radius: 5px;
    box-shadow: 1px ​0px 3px 1px lightgrey;
}
.confirm-modal{
    width: 342px;
    height: 182px;
    border-radius: 5px;
    box-shadow: 1px ​0px 3px 1px lightgrey;
}

.imageInkToInkConfirm{
    width: 463px;
    height: 258px;
}


.device-setting-modal .modal-header{
    height: 8%;
    background-color: #49C9AB;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    padding: 0 1em 0 1em;
    justify-content: space-between;
}
.confirm-modal .modal-header{
    height: 23%;
    background-color: #49C9AB;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    padding: 0 1em 0 1em;
    justify-content: space-between;
}

.device-setting-modal .modal-header .exit, .confirm-modal .modal-header .exit{
    background-image: url('./images/ic_exit_n@2x.png');
    background-size: cover;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.device-setting-modal .modal-header .exit:hover, .confirm-modal .modal-header .exit:hover{
    background-image: url('./images/ic_exit_h@2x.png');
}
.device-setting-modal .modal-header .exit:active, .confirm-modal .modal-header .exit:hover{
    background-image: url('./images/ic_exit_d@2x.png');
}


.device-setting-modal .modal-content{
    height: 84%;
    display: flex;
    justify-content: center;
    padding-top: .8em;
}
.confirm-modal .modal-content{
    height: 48%;
    display: flex;
    justify-content: center;
    /* padding-top: 1.1em; */
    padding: 24px 13px;
    white-space: pre-line;
}

.device-setting-modal .modal-content video{
    height: 215px;
    -webkit-border-radius: 10%;
    border-radius: 5%;
    overflow: hidden;
}
.device-setting-modal .modal-footer{
    height: 8%;
    position: absolute;
    right: 1em;
}
.confirm-modal .modal-footer{
    height: 27%;
    position: absolute;
    right: 1em;
}
.imageInkToInkConfirm .modal-header{
    height: 17%;
}

.imageInkToInkConfirm .modal-content{
    height: 60%;
}

.imageInkToInkConfirm .modal-footer{
    height: 23%;
}


.device-setting-modal .modal-footer button, .confirm-modal .modal-footer button{
    width: 7em;
    height: 2.5em;
    margin-left: 1em;
    cursor: pointer;
    font-family: inherit;
}
.device-setting-modal .device-select-result{
    padding-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.device-select-result .select, .device-select-result .select-microphone{
    width: 90%;
    display: flex;
    margin: 1em 0;
    position: relative;
}
.select-microphone{
    align-items: flex-start;
}

.device-select-result .select > span, .device-select-result .select-microphone > span{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: .3em;
    font-size: .8em;
}
.device-select-result .select-microphone > span{
    margin-top: 6px;
}
.device-select-result .select > select, .device-select-result .select-microphone div> select{
    /* margin-right: 1em; */
    margin-right: 1em;
    width: 75%;
    height: 28px;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-color: transparent;
    border: 1px solid #B2D2CB;
    padding: 0 3em 0 1.3em;
    font-family: inherit;
    font-size: .8em;
    cursor: inherit;
    line-height: inherit;
    /* grid-area: select; */
    /* background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd; */
    outline: none;
}
.device-select-result .select-microphone div> select{
    width: 97%;
}
select::-ms-expand {
    display: none;
}
.device-select-result .select::after, .device-select-result .select-microphone::after{
    content: "";
    width: 0.6em;
    height: 0.45em;
    justify-self: end;
    background-color: #B2D2CB;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    position: absolute;
    right: 1.2em;
    top: 50%;
    transform: translate(0, -50%);
}
.microphone-audio-container{
    height: 8px;
    border-radius: 20px;
    position: absolute;
    bottom: -18px;
}
.microphone-audio-container{
    width: 76% !important;
    background-color: #E2E2E2;
}
.microphone-audio-dynamic{
    border-radius: 20px;
    background-color: #85CEE1;
    height: 100%;
    width: 0%;
    -webkit-transition:  0.2s linear;
    -moz-transition:  0.2s linear;
    -o-transition:  0.2s linear;
    -ms-transition:  0.2s linear;
    transition:  0.2s linear;
}

.device-select-result .select-microphone::after{
    top:12px;
    transform: translate(0, 0);
}



.videos{
    /* display: flex;
    justify-content: space-between; */
    /* margin-top:1vh; */
    /* width: 100%; */
    /* position: absolute; */
    /* pointer-events: none; */
    /* display:grid; */
    /* grid-template-columns: 200px 200px; */
    /* justify-content: space-between; */
    /* max-height: 26vh; */
    /* height: 26vh; */
    /* padding-bottom: 5%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* background: var(--gray-gradient-color); */
    background: #B2D2CB;
    border-radius: 0px 0px 8px 0px;
    position: relative;
    color: #FFFFFF;
    align-items: center;
    overflow:hidden;
    
    /* width: var(--videos-width); */
    /* flex: 0 0 var(--videos-width); */
}
.videos .description{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #45635D 0% 0% no-repeat padding-box;
    font: normal normal normal 20px/14px Roboto;
}

.close-panel{
    background: url('./images/ic_video-bar-collapse_n@2x.png');
     width: 20px;
     height: 40px;
     background-size: cover;
     cursor: pointer;
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     left: calc(100vw - var(--videos-width) );
     z-index: 99;
 }
 .open-panel{
    background: url('./images/ic_video-bar-expand_n@2x.png');
     width: 20px;
     height: 40px;
     background-size: cover;
     cursor: pointer;
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     left: calc(100vw - 40px);
     z-index: 99;
 }

.video-wrapper{
    
    /* min-width: 280px; */
    width: 202px;
    background: #45605D 0% 0% no-repeat padding-box;
    height: 152px;
    border: 1px solid #6DD2BA;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 10px;
    font: normal normal normal 18px/14px Roboto;
}
.video-wrapper .voice-detect.hasVoice.stop{
    display: none;
}
.video-wrapper .voice-detect.noVoice.stop{
    display: none;
}

.video-wrapper .kick{
    background-image: url('./images/ic_video-control_more_n@2x.png');
    width: 32px;
    height: 32px;
    z-index: 1;
    background-size: cover;
    position: absolute;
    left: 97%;
    top: 3%;
    transform: translate(-97%, -3%) rotate(90deg);
    cursor: pointer;
}
.video-wrapper .kick:hover{
    background-image: url('./images/ic_video-control_more_h@2x.png');
}
.video-wrapper .kick:active{
    background-image: url('./images/ic_video-control_more_d@2x.png');
}
.video-wrapper .kick-user-menu{
    position: absolute;
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    width: 140px;
    top: 19%;
    left: 19%;
    z-index: 2;
    font-size: 14px;
    cursor:pointer;
}
.video-wrapper .kick-user-menu:hover{
    background-color:#e0eef5;
}


.video-wrapper video{
    width: 202px;
}
.video-wrapper p{
    color: #FFFFFF;
}
.video-wrapper .user-name{
    position: absolute;
    left: 0;
    text-align: left;
    z-index: 1;
    bottom: -1px;
    color: rgba(255, 255 ,255);
    width: 100%;
    height: 35px;
    padding: 0.2em 40% 0.2em 1em;
    font-size: 16px;
    border-radius: 0 0px 10px 10px;
    font: normal normal normal 18px/26px Roboto;
    background-color: #259F8399;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.user-name.is-opponent{
    background-color:#D1458199;
}

.video-wrapper video{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}
.video-wrapper span{
    position: absolute;
    top:0;
    z-index: -1;
    left: 50%;
    transform: translate(-50%);
}
.video-toolbar{
    padding: 1em 0 1em 0;
    display: flex;
    justify-content: center;
}
.video-toolbar span{
    margin: 0 .7em 0 .7em;
    cursor: pointer;
    background-size: cover;
    width: 39px;
    height: 39px;
    position: relative;
}
.video-toolbar span .warning{
    width: 20px;
    height: 20px;
    position: absolute;
    right: -8px;
    bottom: -4px;
}
.my-video{
    box-shadow: 0px 0px 0px 2.7px #49C9AB;
}
.opponent-video{
    box-shadow: 0px 0px 0px 2.7px #E25A95;
}


.video-toolbar .soundOff{
    background-image: url(./images/ic_video_sound-off_n@2x.png); 
}
.video-toolbar .soundOff:hover{
    background-image: url(./images/ic_video_sound-off_h@2x.png); 
}
.video-toolbar .soundOff:active{
    background-image: url(./images/ic_video_sound-off_d@2x.png); 
}
.video-toolbar .soundOn{
    background-image: url(./images/ic_video_sound-on_n@2x.png); 
}
.video-toolbar .soundOn:hover{
    background-image: url(./images/ic_video_sound-on_h@2x.png); 
}
.video-toolbar .soundOn:active{
    background-image: url(./images/ic_video_sound-on_d@2x.png); 
}
.video-toolbar .videoOff{
    background-image: url(./images/ic_video_video-off_n@2x.png); 
}
.video-toolbar .videoOff:hover{
    background-image: url(./images/ic_video_video-off_h@2x.png); 
}
.video-toolbar .videoOff:active{
    background-image: url(./images/ic_video_video-off_d@2x.png); 
}
.video-toolbar .videoOn{
    background-image: url(./images/ic_video_video-on_n@2x.png); 
}
.video-toolbar .videoOn:hover{
    background-image: url(./images/ic_video_video-on_h@2x.png); 
}
.video-toolbar .videoOn:active{
    background-image: url(./images/ic_video_video-on_d@2x.png); 
}
.video-toolbar .settings{
    background-image: url(./images/ic_video_setting_n@2x.png); 
}
.video-toolbar .settings:hover{
    background-image: url(./images/ic_video_setting_h@2x.png); 
}
.video-toolbar .settings:active{
    background-image: url(./images/ic_video_setting_d@2x.png); 
}

/* toolbar */
.toolbar-wrapper{
    min-width: var(--toolbar-wrapper-width);
    max-width: var(--toolbar-wrapper-width);
    display: flex;
    justify-content: center;
    padding-top: .4em;
    background: #E3F5F1;
    border-top: 1px solid #808A88;
}

.toolbar{
    /* position: absolute; */
    text-align: center;
    list-style: none;
    background: #E3F5F1;
    /* top: 100px; */
    /* left: 5%; */
    width: var(--toolbar-width);
    /* max-height: 523px; */
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 80px;
    opacity: 1;
    top: 1em;
    left: 1em;
    z-index:2;
    /* zoom: 1.2; */
    cursor: pointer;
    /* background-image: url(./image/bg_function-bar_01.png); */
}
.toolbar li{
    display: flex;
    justify-content: center;
    position: relative;
}
.toolbar li .toolbar-icon::before,
.toolbar li .toolbar-detail>span::before,
.color-picker-bg .color-picker-wrapper>span::before,
.video-toolbar .audio>span::before,
.video-toolbar .video>span::before,
.video-toolbar .device-setting>span::before{
    --scale: 0;
    position: absolute;
    top: calc(var(--toolbar-icon-height));
    left: 0;
    transform: scale(var(--scale));
    z-index: 1;
    pointer-events: none;
    transition: 20ms transform;
    transform-origin: bottom center;
    content: attr(data-tooltip);
    background: #f1f2f3;
    border:1px solid #494949;
    color:#494949;
    padding: 3px 6px;
    font-size: 13px;
    white-space: nowrap;
    border-radius: .1rem;
}
.video-toolbar .audio>span::before,
.video-toolbar .video>span::before,
.video-toolbar .device-setting>span::before{
    left: -31%;
    padding: 0px 6px;
    top: 36px;
    line-height: 15px;
    /* width: max-content; */
    text-align: center;
    white-space: normal;
    font-size: 11px;
}
.videoBlock.block{
    display: block !important
}
.videoBlock.nonblock{
    display: none !important
}


.whiteboard-options-control .control-icon>span::before{
    --scale: 0;
    position: absolute;
    /* top: calc(var(--toolbar-icon-height)); */
    left: 0;
    transform: scale(var(--scale));
    z-index: 1;
    pointer-events: none;
    transition: 20ms transform;
    transform-origin: bottom center;
    content: attr(data-tooltip);
    background: #f1f2f3;
    border:1px solid #494949;
    color:#494949;
    padding: 3px 6px;
    font-size: 13px;
    white-space: nowrap;
    border-radius: .1rem;
    top: -24px;
}
.whiteboard-options-control .control-icon{
    position: relative;
    top: 0px;    
    max-width: 1300px;
    width: max-content;
}

.whiteboard-options-control .page-control-container .pageControl>span::before{
    --scale: 0;
    position: absolute;
    transform: scale(var(--scale));
    z-index: 1;
    pointer-events: none;
    transition: 20ms transform;
    transform-origin: bottom center;
    content: attr(data-tooltip);
    background: #f1f2f3;
    border:1px solid #494949;
    color:#494949;
    padding: 3px 6px;
    font-size: 13px;
    white-space: nowrap;
    border-radius: .1rem;
    top: -7px;
}
.insert-page-icon::before, .more-action::before{
    --scale: 0;
    transform: scale(var(--scale));
    position: absolute;
    z-index: 100;
    pointer-events: none;
    transition: 20ms transform;
    transform-origin: bottom center;
    content: attr(data-tooltip);
    background: #f1f2f3;
    border:1px solid #494949;
    color:#494949;
    padding: 3px 6px;
    font-size: 13px;
    right: 5px;
    width: max-content;
}
.more-action::before{
    right: 25px;
    z-index: 20;
    --scale: 0;
}

html.no-touch .whiteboard-options-control .page-control-container .pageControl>span:hover::before,
html.no-touch .toolbar li .toolbar-icon:hover::before,
html.no-touch .toolbar li .toolbar-detail>span:hover::before,
html.no-touch .color-picker-bg .color-picker-wrapper>span:hover::before,
html.no-touch .video-toolbar .audio>span:hover::before,
html.no-touch .video-toolbar .video>span:hover::before,
html.no-touch .video-toolbar .device-setting>span:hover::before,
html.no-touch .whiteboard-options-control .control-icon>[data-tooltip]:hover::before,
html.no-touch .insert-page-icon:hover::before,
html.no-touch .more-action:hover::before{
    --scale: 1;
}


/*
 material ui override
*/
/* .css-nnbavb{
   width: 0;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    top: -10px !important;
} */



/* .toolbar::after{
    content: "";
    height: 20px;
    display: block;
} */
.toolbar-group{
    max-height: 0px;
    transition: 0.2s;
    overflow: hidden;
}
.toolbar-group-show{
    max-height: 100%;
}

.toolbar-icon{
    padding: var(--toolbar-icon-padding);
    width: 100%;
    height: var(--toolbar-icon-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.toolbar-icon-hover-active{
    background: #49C9AB;
}
.toolbar-icon img{
    width: 28px;
    height: 24px;
}

.toolbar-detail{
    position: absolute; 
    left: var(--toolbar-width);
    height: var(--toolbar-icon-height);
    top: 0;
    display: grid;
    border-radius: 0px 2px 2px 0px;
    z-index: 999;
}

.toolbar-detail .col{
    display: flex;
    align-items: center;
    position:relative;
}
.toolbar-detail img{
    width: 28px;
    height: 24px;
}

.page-toolbar{
    height: 55px;
    position: absolute;
    width: max-content;
    left: 30%;
    bottom: 80px;
    background: #E3F5F1 0% 0% no-repeat padding-box;
    transform: translate(-30%);
    border: 1px solid #808A88;
    display: flex;
    align-items: center;
}
.page-toolbar div{
    width: 50px;
    margin: 0 10px 0 10px;
    text-align: center;
}
.page-toolbar .page-controller{
    cursor: pointer;
}
.page-toolbar .page-controller:hover{
    transform: scale(1.1) translate(1.5px, -1.5px);
}
.currentPageIdx{
    width: 2.5em;
    margin-right: .3em;
    max-height: 1.32em;
    min-height: 1.32em;
    font-size: 0.85em;
    padding-bottom: 0;
}
.currentPageIdx-container{
    font-size: 1em;
}




/*new color picker*/
.color-picker-bg{
    position: absolute;
    bottom: 0;
    left: var(--toolbar-width);
    background: url('./images/img_color-set_bg_01.png');
    width: 99px;
    background-repeat: no-repeat;
    height: 206px;
    z-index:998;
}
.color-picker-bg-horizontal{
    background: url('./images/img_color-set_bg_h_01@2x.png');
    width: 209px;
    height: 106px;
    transform: translate(0, -3%);
}
.color-picker-bg-horizontal.up{
    background: url('./images/img_color-set_bg_h_02@2x.png');
    background-position-y: bottom;
}


.color-picker-wrapper{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    height: 193px;
    width: 78px;
    position: absolute;
    top: 5px;
    left: 13px;
    cursor: pointer;
}
.color-picker-wrapper span{
    position:relative;
}
.color-picker-wrapper span:hover{
    outline: 1px solid #000;
    z-index: 2;
}
.color-picker-wrapper-horizontal{
    top: 24px;
    left: 12px;
    width: 193px;
    height: 78px;
    grid-template-columns: repeat(8, 1fr);
}
.up .color-picker-wrapper-horizontal{
    top: 4px;
    left: 12px;
}

/* color picker */
.color-picker-container{
    display: flex;
    flex-wrap: nowrap;
    z-index: 2;
}

.color-picker{
    height: 28px;
    width: 28px;
    border-radius: 50%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.color-picker.selected, .color-picker:hover{
    box-shadow: 0px 0px 5px 5px #fff;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    background: #ddf2ed;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin: 0;
    border: 1px solid #c3cccf;
    border-radius: 5px;
    transform: translate(0, -50%);
    /* transform: rotate(90deg); */
  }
  
  /* .slider:hover {
    opacity: 1;
  } */
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 22px;
    background: #6fd1be;
    cursor: pointer;
    border-radius: 5px;
  }
  .slider::-webkit-slider-thumb:hover{
      background: #9dd3c9;
  }
 

  .slider::-moz-range-thumb {
    width: 8px;
    height: 22px;
    background: #6fd1be;
    cursor: pointer;
  }
  .slider::-moz-range-thumb:hover{
    background: #9dd3c9;
  }
  .toolbar-detail.imageinkslider-detail{
    bottom: 0;
    height: 22px;
    cursor: pointer;
    transform: translateX(-100%);
    /* transform: rotate(90deg) translateY(-65%); */
    /* transform: translate(-100%, 84%); */
    border-radius: 5px;
    /* transform: rotate(90deg) translate(67px, -40px); */
    transform-origin: 0;
    width: 131px;
  }




/* media query */
@media(min-width: 600px){
    .App{
        font-size: 1.2rem;
    }
    /* .nav-links li, .nav-links .toolbar button{
        font-size: 1.2rem;
    } */
    .nav-links li{
        font-size: 1.2rem;
    }
    .room-nav{
        flex-wrap: nowrap;
        flex-direction: row;
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .room-nav .title {
        padding: .3rem;
        display:block;
    }
    /* .toolbar{
        width: 60%;
    } */
    
    .home{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media(min-width: 992px){
    .home{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media(min-width: 1280px){
    .home{
        grid-template-columns: repeat(4, 1fr);
    }
}


@keyframes opacityToZero {
    0%{
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes opacityToNormal {
    0%{
        opacity: 0;
    }
    40%{
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}